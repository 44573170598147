@import "./variables.module.scss";

.card {
  text-align: left;
  color: $text_color;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  display: flex;
  background-color: rgb(255 255 255 / 75%) !important;
  justify-content: center;
  width: 100% !important;
  align-items: center;
  position: relative;
  flex-direction: column;
  // z-index: 1;

  width: $card_width;
  height: $card_height;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  h2 {
    margin: 0 0 1rem 0;
    font-size: $font_size_h2;
    color: $color_primary;
  }

  h3 {
    font-size: 50px;
    line-height: 48px;
    letter-spacing: -.005em;
    font-weight: 700;
  }

  p {
    line-height: 40px;
  }

}
