* {
  padding: 0px;
  margin: 0px;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  font-family: "Inter var", Inter, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple ColorDef Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto ColorDef Emoji";
  height: 100%;
  width: 100%;
  background-color: rgb(245, 245, 248); /* background base*/
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
}

body .sv-tagbox__item {
  height: auto;
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

/* Loader */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: rgb(99, 102, 241);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Survey */
.nav-button {
  margin-left: auto; 
  margin-right: 0;
}

.nav-input {
  color: #ffffff;
  background-color: #ff9814;
}

.nav-input:hover {
  background-color: #f28a05;
}

/* Map Popup */
.div.mapboxgl-popup {
  max-width: 400px;
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
}

div.mapboxgl-popup-content {
  border-radius: 10px;
  padding: 12px;
}

.mapboxgl-popup-close-button {
  font-size: 15px;
  margin-right: 5px;
}

@media (max-width: 1980px) {
  div.Login_paper__BZeKx {
    max-width:100vw;
  }
  .container {
    padding: 0;
  }
}

/* Survey */
.nav-button {
  margin-left: auto;
  margin-right: 0;
}

.nav-input {
  color: #ffffff;
  background-color: #ff9814;
}

.nav-input:hover {
  background-color: #f28a05;
}

body .sv-popup {
  position: fixed;
  left: 0;
  top: 0;
  width: 0vw;
  outline: none;
  z-index: 2000;
  height: 0vh;
}

body .svc-designer__placeholder-container {
  min-height: 50vh;
}

/* body .svc-tab-designer .svc-tab-designer_content{
  min-height: 60vh;
} */

body .svc-side-bar__container {
  min-width: 250px;
  width: 350px;
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 512px) and (max-width: 768px) {
  .container {
    margin-left: 10px;
    margin-right: 10px;
  }
  div.Login_paper__BZeKx {
    max-width:70vw;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    margin-left: 20px;
    margin-right: 20px;
  }
  div.Login_paper__BZeKx {
    max-width:50vw;
  }
}


/* Desktops and laptops ----------- */
@media only screen and  (min-width: 1024px) and (max-width: 1600px)  {
  .container {
    margin-left: 50px;
    margin-right: 50px;
  }
  div.Login_paper__BZeKx {
    max-width:35vw;
  }
}


/* Large screens ----------- */
@media only screen and (min-width: 1600px) {
  .container {
    margin-left: 100px;
    margin-right: 100px;
  }
  div.Login_paper__BZeKx {
    max-width:25vw;
  }
}
