@import "./variables.module.scss";

.container {
  padding: 0 20px;
  color: $text_color;
  position: relative;
  z-index: 1; // 2 messes up the pagination list
  top: -50px;

  form {
    margin-top: 40px;
    margin-bottom: 40px;
    border-radius: 20px;
    background-color: $color_primary_light;
    height: $upload_form_height;
    padding: 40px 0;
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    label {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;

      strong {
        font-size: 18px;
      }

      input {
        cursor: pointer;

        &:hover {
          color: grey;
          font-weight: 600;
        }
      }
    }
  }

  .preview {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    img {
      margin-top: 25px;
      margin-bottom: 25px;
    }

    table {
      border-collapse: collapse;
      margin: 25px 0;
      font-size: $font_size_table;
      font-family: sans-serif;
      min-width: 400px;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      background-color: $color_primary_contrast;

      thead {
        tr {
          background-color: $color_primary;
          color: $color_primary_contrast;
          text-align: left;
        }
      }

      th,
      td {
        padding: 12px 15px;
      }

      tbody {
        tr {
          border-bottom: 1px solid $background_toolbar;
        }

        &:nth-of-type(even) {
          background-color: $color_primary_contrast;
        }

        &:last-of-type {
          border-bottom: 2px solid $color_primary;
        }
      }
    }

    strong {
      color: $color_primary;
    }

    figcaption {
      font-style: italic;
      padding: 10px;
      text-align: center;
    }
  }
}
