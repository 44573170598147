@import "./variables.module.scss";

.tableContainer {
  padding: $padding_main;
  position: relative;
  // z-index: 1; // 2 messes up the pagination list
  // top: calc($layout_box_height - $table_toolbar_height - $padding_main);
  // gap: 5px;
  // height: calc(100vh - 250px);
  overflow-y: auto;
  // overflow-x: scroll;

  .icon {
    color: inherit;

    &:hover {
      // color: $background_toolbar_secondary;
      background-color: $background_toolbar;
    }
  }

  h2 {
    color: $text_color;
    font-size: $font_size_h2;
    border-right: 1px solid $background_toolbar_secondary;
    padding-right: 1rem;
    white-space: nowrap;
  }
}
