@import "./variables.module.scss";

.surveyContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // z-index: 1;
  // top: calc($layout_box_height - $table_toolbar_height);
  top: $padding_main;
  transform: translateY(-60px);

  --primary: #6366f1;
  --background: #ffffff;
  --background-dim: #f3f3f3;
  --background-dim-light: #f9f9f9;
  --primary-foreground: #ffffff;
  --foreground: rgba(0, 0, 0, 0.87);
  --base-unit: 8px;
  --font-family: "Inter var", Inter, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple ColorDef Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto ColorDef Emoji";
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  // z-index: 1;
  // top: calc($layout_box_height - $table_toolbar_height);
  top: $padding_main;
  margin-top: $margin_main;
}

.list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: $margin_main;
}

.exportBar {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  // max-width: 800px;
  // margin-top: $margin_main;
  margin-left: auto;
  margin-right: auto;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
    margin: 1rem;
    flex-basis: 20%;
    // flex-basis: 15%; //poll
    padding: 50px;
    text-align: left;
    color: $text_color;
    text-decoration: none;
    border: 1px solid #eaeaea;
    border-radius: 10px;
    transition: color 0.15s ease, border-color 0.15s ease;
    background-color: rgba(256, 256, 256, 0.8);
    // height: 350px; //poll
    height: 450px;
    box-shadow: 1px 1px 10px 10px rgb(0 0 0 / 5%);

    &:hover,
    &:focus,
    &:active {
      color: $color_primary;
      border-color: $color_primary;
    }

    // .arrow {
    //   display: flex;
    //   flex-direction: row;
    //   flex-wrap: nowrap;
    //   align-items: center;
    // }

    h3 {
      margin: 0 0 1rem 0;
      font-size: 1.5rem;
    }

    p {
      margin: 0;
      font-size: 1.25rem;
      line-height: 1.5;
    }

    .history {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 7px;
    }

    .disabled {
      pointer-events: none;
    }

    span {
      margin-top: 10px;
      // line-height: 40px;
      color: $color_warn;
    }
  }
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}
