@import "./variables.module.scss";

.paper {
  max-width: 25vw;
  padding: $padding_main;
  margin-top: $margin_main;
  margin-bottom: $margin_main;
  margin-left: auto;
  margin-right: auto;
  // background-color: $primary;
  border-radius: $border_radius_box;

  .box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
      margin-top: auto;
      margin-bottom: auto;
      margin-left:10px;

      h2 {
        font-size: $font_size_h2;
        color: $color_primary;
        margin-bottom: 20px;
      }
    }
  }
}
