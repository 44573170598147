@import "./variables.module.scss";

.login_header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: $color_primary;
  font-size: $font_size_h1;
  padding-top: $padding_header;
  padding-bottom: $padding_header;
  border-bottom: 10px solid $color_primary;

  // transform: translate(-240px, -225px);  
  .logo {
    display: flex;
    flex-direction: row;
    text-align: center;
    margin-left: $margin_header;
  }
}

