@import "./variables.module.scss";

.grid{

  @media screen and (max-width: $small) {
    display: none!important;
  }
}

.expand{

  @media screen and (max-width: $small) {
    width: 100%,
  }
}
.paper {
  margin-bottom: $margin_main;
  @media screen and (max-width: $small){
    width: 90%;
    padding: $padding_main;
    margin-top:0;
  }

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
    gap: $gap_fields;
    justify-content:space-between;
    @media screen and (max-width: $small) {
      padding: $padding_login_box 5px;
    }
    h2 {
      font-size: $font_size_h2;
      color: $color_primary;
    }

    .form {
      .fields {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: $gap_fields;
        
      }
    }
  }
}
